
import { IPerizia, IResult } from './../models/perizia.interface';
import { IUtente } from '../models/utente.interface';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { IFile } from '../models/incarico.interface';
import { IChat } from '../components/chat/models/chat.interface';
import { IDamage } from '../models/damages.interface';
import { Appointment } from 'devextreme/ui/scheduler';
import { MD5, thumbnailify } from '../shared';
import { Platform, ToastController } from '@ionic/angular';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Storage } from '@ionic/storage';

@Injectable()
export class MysqlService {

  URL_BASE = '';
  marche: any[];
  stati_perizia: any[];

  public uploadQueue: IFileQueue[] = []
  public uploadInProgress: number = null;
  public fileProgress = 0;

  constructor(
    private http: HttpClient,
    private router: Router,
    private userService: UserService,
    private platform: Platform,
    public ionicStorage: Storage,
    private toastController: ToastController,
  ) {



    //this.URL_BASE = window.location.href.split('/').slice(0, 3).join('/');

    //let host = window.location.href.split('/')[2].split(':')[0];

    let env = localStorage.getItem('ENVIRONMENT');
    let envs = []
    try {
      let tmp = localStorage.getItem('ENVIRONMENTs')
      if (tmp) envs = JSON.parse(tmp);
      this.URL_BASE = envs.find(e => e.env == env).url_base
    } catch (error) {
      console.error("db non configurato")
      this.URL_BASE = 'https://xpert-test.ggroup.cloud/api/api.php?cmd='
    }

    console.log('api constructor: ' + this.URL_BASE)

    this.http.get(this.URL_BASE + 'list_perizia_stati').toPromise()
      .then((res: any[]) => {
        this.stati_perizia = res
        localStorage.setItem('stati_perizia', JSON.stringify(res))
      })
      .catch(err => console.error(err))

    this.etax_list_marche().then((res: any[]) => {
      this.marche = res
      localStorage.setItem('marche', JSON.stringify(res))
    })
      .catch(err => console.error(err))



  }

  
  utenti_validate_otp(otp:string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      otp:otp
    }

    return this.http.post(this.URL_BASE + 'utenti_validate_otp', data).toPromise();

  }

  utenti_send_otp_by_email(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'utenti_send_otp_by_email', data).toPromise();

  }



  get_perizia_result_image_url(img: string) {
    return `${this.URL_BASE}get_perizia_result_image_url&img=${img}`;
  }

  unipolrental_confirm_appointment(id, dtaAppuntamento) {

    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      dtaAppuntamento: dtaAppuntamento
    }

    return this.http.post(this.URL_BASE + 'unipolrental_confirm_appointment', data).toPromise();

  }

  list_appuntamenti(ispettore = this.userService.getCurrentUser(), showId = 0): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      email: ispettore,
      showId: showId
    }
    return this.http.post(this.URL_BASE + 'list_appuntamenti', data).toPromise();

  }

  sms_send(dest: string, msg: string, send: string, return_id = 0): Promise<any> {
    let data = {
      //auth: this.userService.getCurrentUser(),
      dest: dest.replace('+', ''),
      msg: msg,
      send: send,
      return_id: return_id
    }

    return this.http.post(this.URL_BASE + 'sms_send_anon', data).toPromise();
  }


  fleet_generate_perizia(id: string, washed: boolean): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      washed: washed,
    }
    return this.http.post(this.URL_BASE + 'fleet_generate_perizia', data).toPromise();
  }

  fleet_get_by_plate(plate: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      plate: plate,
    }
    return this.http.post(this.URL_BASE + 'fleet_get_by_plate', data).toPromise();
  }



  commesse_get(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'commesse_get', data).toPromise();
  }


  get_tot_damage(vehType: string, damages: IDamage[]): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      vehType: vehType,
      damages: damages
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'get_tot_damage', data).toPromise();
  }


  ///////////////////////////////////////////////////////////////////////////////////////////



  cloudmessaging_upsert_device_id(token: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      deviceId: token
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'cloudmessaging_upsert_device_id', data).toPromise();
  }

  etax_list_marche(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser()
    }

    return this.http.post(this.URL_BASE + 'etax_list_marche', data).toPromise();
  }

  etax_list_anni(marca: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      marca: marca
    }

    return this.http.post(this.URL_BASE + 'etax_list_anni', data).toPromise();
  }

  etax_list_modelli(marca: string, anno: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      marca: marca,
      anno: anno
    }

    return this.http.post(this.URL_BASE + 'etax_list_modelli', data).toPromise();
  }

  etax_list_versioni(marca: string, anno: string, modello: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      marca: marca,
      anno: anno,
      modello: modello
    }

    return this.http.post(this.URL_BASE + 'etax_list_versioni', data).toPromise();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////

  list_province(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser()
    }

    return this.http.post(this.URL_BASE + 'list_province', data).toPromise();
  }
  verycar_send_to_uxpert(id, mail): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      mail: mail

    }

    return this.http.post(this.URL_BASE + 'verycar_send_to_uxpert', data).toPromise();
  }
  list_comuni(prov: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      prov: prov
    }

    return this.http.post(this.URL_BASE + 'list_comuni', data).toPromise();
  }

  openBadaPage(item) {
    const { id, targa, marca, modello, versione } = item;
    const queryParams = new URLSearchParams({ id, targa, marca, modello, versione });
    const baseUrl = 'http://localhost:8100/#/new';
    const fullUrl = `${baseUrl}?${queryParams.toString()}`;
    window.open(fullUrl, '_blank');
  }

  ///////////////////////////////////////////////////////////////////////////////////////////

  get_print_adz(id: number, download: boolean) {
    return this.URL_BASE + 'print_adz&header=ie&id=' + id + (download ? '&download=1' : '');
  }

  get_print_adz_perizie(id: number, download: boolean, hash: string, thumb: boolean = false) {
    return this.URL_BASE + 'print_adz_perizie&header=ie&id=' + id
      + '&hash=' + hash
      + (download ? '&download=1' : '')
      + (thumb ? '&thumb=1' : '');
  }

  get_export_url() {
    return this.URL_BASE + 'export';
  }

  get_exp_tab1_url(id: number) {
    return this.URL_BASE + 'exp_tab1&id=' + id;
  }

  get_print_tab1_url(id: number) {
    return this.URL_BASE + 'print_tab1&id=' + id;
  }


  //////////////////////////////////////////////////////////////////////////////////////////

  get_perizia(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      type: ''
    }

    return this.http.post(this.URL_BASE + 'get_perizia', data).toPromise();
  }

  async perizia_upsert_result(id: number, result: IResult): Promise<any> {

    await this.manageUploadQueue(id, result)

    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      result: result
    }

    return this.http.post(this.URL_BASE + 'perizia_upsert_result', data).toPromise();

  }




  async manageUploadQueue(id: number, data: IResult) {

    if (!data) return
    //console.log('manageUploadQueue', data)
    if (!data.fotoVetrina) data.fotoVetrina = []
    for (let i = 0; i < data.fotoVetrina.length; i++) {



      data.fotoVetrina[i].url = null
      let url = await this.addFoto('' + id, 'foto_vetrina', data.fotoVetrina[i].id, data.fotoVetrina[i].img);
      //data.fotoVetrina[i].thumbData=null;

      if (url) {
        data.fotoVetrina[i].img = url

      }
    }

    if (!data.altriElementi) data.altriElementi = []
    for (let i = 0; i < data.altriElementi.length; i++) {
      data.altriElementi[i].url = null
      let url = await this.addFoto('' + id, 'altri_elementi', data.altriElementi[i].id, data.altriElementi[i].img);
      //data.altriElementi[i].thumbData=null;
      if (url) {
        data.altriElementi[i].img = url
      }
    }


    if (!data.documenti) data.documenti = []
    for (let i = 0; i < data.documenti.length; i++) {
      data.documenti[i].url = null
      let url = await this.addFoto('' + id, 'documenti', data.documenti[i].id, data.documenti[i].img);
      //data.documenti[i].thumbData=null;
      if (url) {
        data.documenti[i].img = url
      }
    }

    if (!data.attachments) data.attachments = []
    for (let i = 0; i < data.attachments.length; i++) {

      //console.log(data.attachments[i])

      data.attachments[i].url = null
      let url = await this.addFoto('' + id, 'attachments', data.attachments[i].id, data.attachments[i].img);
      //data.attachments[i].thumbData=null;
      if (url) {
        data.attachments[i].img = url
      }
    }

    if (!data.damages) data.damages = []
    for (let i = 0; i < data.damages.length; i++) {
      data.damages[i].img1.url = null
      let url1 = await this.addFoto('' + id, 'danni', data.damages[i].img1.id, data.damages[i].img1.img);
      //data.damages[i].img1.thumbData=null;
      if (url1) {
        data.damages[i].img1.img = url1
      }

      /*
      let url2 = await this.addFoto('' + id, 'danni', data.damages[i].img2.id, data.damages[i].img2.img);
      //data.damages[i].img2.thumbData=null;
      if (url2) {
        data.damages[i].img2.img = url2
      }
      */


    }

    if (!data.damagesInt) data.damagesInt = []
    for (let i = 0; i < data.damagesInt.length; i++) {
      data.damagesInt[i].img1.url = null
      let url1 = await this.addFoto('' + id, 'danniInt', data.damagesInt[i].img1.id, data.damagesInt[i].img1.img);
      //data.damages[i].img1.thumbData=null;
      if (url1) {
        data.damagesInt[i].img1.img = url1
      }

      if (data.damagesInt[i].img2) {
        data.damagesInt[i].img2.url = null
        let url2 = await this.addFoto('' + id, 'danniInt', data.damagesInt[i].img2.id, data.damagesInt[i].img2.img);
        //data.damages[i].img2.thumbData=null;
        if (url2) {
          data.damagesInt[i].img2.img = url2
        }
      }



    }

    if (!data.glasses) data.glasses = []
    for (let i = 0; i < data.glasses.length; i++) {
      data.glasses[i].img1.url = null
      let url1 = await this.addFoto('' + id, 'glasses', data.glasses[i].img1.id, data.glasses[i].img1.img);
      //data.damages[i].img1.thumbData=null;
      if (url1) {
        data.glasses[i].img1.img = url1
      }

      data.glasses[i].img2.url = null
      let url2 = await this.addFoto('' + id, 'glasses', data.glasses[i].img2.id, data.glasses[i].img2.img);
      //data.damages[i].img2.thumbData=null;
      if (url2) {
        data.glasses[i].img2.img = url2
      }

    }

    if (data.tiresData && data.tiresData.tires)
      for (let i = 0; i < data.tiresData.tires.length; i++) {

        let tire = data.tiresData.tires[i]

        for (let j = 0; j < tire.img?.length; j++) {

          let img = tire.img[j]

          img.url = null
          let url1 = await this.addFoto('' + id, 'tires', `${i}_${j}`, img.img);
          //data.damages[i].img1.thumbData=null;
          if (url1) {
            img.img = url1
          }

        }
      }

  }

  async addFoto(id: string, prefix: string, attachmentId: any, image: string) {

    //console.log(image)

    if (!image || image == '') {
      //console.log('già uploadata 1')
      return null; // già uploadata
    }
    if (image.slice(0, 4) == 'file') {
      //console.log('già uploadata 2')
      return null; // già uploadata
    }
    //console.log('addFoto', id, prefix, attachmentId, image)

    //console.log('attachmentId', attachmentId)


    if (attachmentId == null) {
      attachmentId = MD5(image)
    }

    let pathname = (this.platform.is('android') ? '' : 'Documents/') + `UxpertPRO/${id}/${prefix}`
    let filename_local = `${attachmentId}.jpg`;


    let filename = `files/automotive_insurance/${id}/${prefix}/${attachmentId}.jpg`;

    let dir = this.platform.is('android') ? Directory.Documents : Directory.Documents



    //console.clear()
    //console.log('savelocal')
    //if (this.platform.is('capacitor'))
    await this.savelocal(dir, pathname, filename_local, image)

    this.pushtoUploadQueue(filename, image);



    return filename;

  }

  async savelocal(directory, pathname, filename, data: string) {

    try {

      await Filesystem.deleteFile({
        directory: directory,
        path: `${pathname}/${filename}`,
      })

    } catch (error) {

    }

    await Filesystem.writeFile({
      directory: directory,
      path: `${pathname}/${filename}`,
      data: data,
      recursive: true
    })

      //this.file.writeFile(pathbase, pathname + '/' + filename, blob, { append: false, replace: true })

      .then(res => { })
      .catch(err => {
        //alert('savelocal - writeFile err ' + JSON.stringify(err))
      })


  }

  async pushtoUploadQueue(filename: string, content: string) {

    content = content.replace('data:image/jpeg;base64,', '')
    await thumbnailify('data:image/jpeg;base64,' + content, 1080, 0.8).then(res => content = res)
    let md5 = MD5(content.replace('data:image/jpeg;base64,', ''))

    let el: IFileQueue = {
      filename: filename,
      content: content,
      md5: md5
    }

    this.uploadQueue.push(el);
    this.backupUploadQueueElement(el)

    this.resumeUploadService();


  }

  NOTIFICATION_ID = 1
  async resumeUploadService() {


    //this.setNotifyNFiles();

    // this.backgroundMode.enable();


    if (this.uploadInProgress) return;
    if (this.uploadQueue.length <= 0) return;
    //this.setToastNotifyNfiles();

    let skipped = false;

    // Schedule a single notification



    for (let i = 0; i < this.uploadQueue.length; i++) {


      let x = i;
      if (this.uploadQueue[x] == null) continue;

      let item = JSON.parse(JSON.stringify(this.uploadQueue[x]))

      //this.setNotifyNFiles();

      //alert('ionicStorage.get ' + this.uploadQueue[i].filename)

      await this.ionicStorage.get(item.filename).then(value => {

        //alert('value ' + JSON.stringify(value))
        value = JSON.parse(value)

        item.content = value.content
        item.md5 = value.md5

        //alert('ionicStorage.get -> ok')
      }).catch(err => {
        //alert('err - ' + this.uploadQueue[i].filename + ' ' + JSON.stringify(err))

      })



      this.uploadInProgress = x;

      if (!this.uploadQueue || !item || !item.content || item.content == '') { skipped = true; continue };

      //alert('loaded')

      //if (this.uploadQueue[i].content == '') continue;


      let data = {
        auth: this.userService.getCurrentUser(),
        data: JSON.parse(JSON.stringify(item))
      }

      data.data.filename.replace('uq_', '')

      await this.http.post(this.URL_BASE + 'perizie_upload_file', data).toPromise()

        .then(async (res: any) => {

          //console.log(res);


          if (res.status == 'OK') {

            //if (this.platform.is('capacitor'))


            await this.checkMd5(item.filename.replace('uq_', '')).then(async (res: any) => {
              // console.log(res);
              if (res.hash == item.md5) {
                let ok = false;
                //await this.ionicStorage.set('bk_' + item.filename.replace('uq_', ''), item).then(res => ok = true).catch(err => console.log(err.message))
                await this.ionicStorage.remove('uq_' + item.filename.replace('uq_', '')).catch(err => console.log(err.message))
                console.log('foto caricata ' + item.filename);
              }
              else {

                // alert(item.filename.replace('uq_', '') + ' ' + res.hash + " == " + item.md5)

              }
            })
              .catch(err => {
                console.error(err)
                alert('error - checkMd5 ' + JSON.parse(err))

              })



          } else {

            let toast = await this.toastController.create({
              message: 'e1 ' + JSON.stringify(res.error),
              duration: 2000
            })
            //toast.present()

          }

          this.uploadInProgress = null;
          this.restoreUploadQueue(true);

          return;


        }).catch(async err => {
          //alert('catch ' + JSON.stringify(err));
          console.log(err);

          if (item && !err.message.startsWith('Http failure')) {
            let toast = await this.toastController.create({
              message: `e2 ${err.message} - i: ${x} - uploadQueue[x]: ${item}`,
              duration: 2000
            })
            //toast.present()
          }


          this.uploadInProgress = null;
          this.restoreUploadQueue(true);

          return;

        })


      //break;

    }

    if (skipped) this.resumeUploadService()

  }


  async checkMd5(img: string): Promise<any> {
    return this.http.get(this.URL_BASE + 'get_perizia_result_image_md5&img=' + img).toPromise()
  }

  async backupUploadQueueElement(el: IFileQueue) {
    try {
      //if (this.platform.is('capacitor'))
      await this.ionicStorage.set('uq_' + el.filename, JSON.stringify(el))/*.then(res=>alert(JSON.stringify(res)))*/.catch(err => alert(err.message))
      //else
      //  localStorage.setItem('uq_' + el.filename, JSON.stringify(el))
    } catch (error) {
      alert(error.message)
    }
  }

  async restoreUploadQueue(start: boolean) {


    let tmp: IFileQueue[] = [];

    let keys;
    await this.ionicStorage.keys().then(async res => {
      keys = res.filter(key => key.substr(0, 3) == 'uq_');
    })

    let n = keys.length;
    //alert("keys.length " + n)

    if (n == 0) {
      this.uploadQueue = []
      return;
    }


    for (let i = 0; i < keys.length; i++) {

      let el: IFileQueue = {
        filename: keys[i],
        content: '',
        md5: ''
      }

      tmp.push(el)

      //alert("key: " + keys[i])
      /*
            await this.ionicStorage.get(keys[i]).then(value =>
              tmp.push(JSON.parse(value))
            ).catch(err => {
              alert(JSON.stringify(err))
            })
      */
    }



    //alert("loaded")

    this.uploadQueue = []
    this.uploadQueue = tmp;

    n = tmp.length;
    //alert("uploadQueue.length " + n)


    //this.setNotifyNFiles()

    if (n <= 0) return

    if (start) {
      //this.backgroundMode.enable();
      this.resumeUploadService();
    }

    /*
        return;
    
        alert("ionicStorage.forEach")
        await this.ionicStorage.forEach((value, key, index) => {
    
          alert(key)
    
          alert(value)
    
          if (key.substr(0, 3) == 'uq_') {
    
    
    
            tmp.push(JSON.parse(value))
          }
    
          n--;
    
          if (n <= 0) {
    
            this.uploadQueue = []
            this.uploadQueue = tmp;
            if (start) {
              //          this.backgroundMode.enable();
              //this.resumeUploadService();
            }
          }
    
        }).catch(err => {
          alert(JSON.stringify(err))
        })
    
    */



  }

  uploadServiceHandler() {
    while (true) {
      this.resumeUploadService();
    }
  }

  restartService() {
    this.uploadInProgress = null;
    this.restoreUploadQueue(true);
  }


  clearAllUploadQueue() {

    if (!confirm("SEI SICURO di voler eliminare tutti i file in upload?")) return;

    this.ionicStorage.clear().then(res => {
      this.restoreUploadQueue(true);
    })
  }

  get_perizia_result(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'get_perizia_result', data).toPromise();
  }

  get_perizia_attachment_url(id: number, f: IFile, type: string) {
    return `${this.URL_BASE}get_perizia_attachment&id=${id}&hash=${f.hash}&filename=${f.fileName}&type=${type}`;
  }

  list_perizie(type: string = '', ids = [], limit = 0, stato = '', filterYear = { y1: 0, y2: 3000 }, filters = {}): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      type: type,
      ids: ids,
      limit: limit,
      stato: stato,

      filters: filters
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'list_perizie', data).toPromise();
  }

  list_storico_perizia(id: number): Promise<any> {
    return this.list_storico('perizie', id)
  }

  list_storico(table: string, id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      table: table,
      id: id
    }

    return this.http.post(this.URL_BASE + 'list_storico', data).toPromise();
  }

  list_logs_perizia(id: number): Promise<any> {
    return this.list_logs('perizie', id)
  }

  list_logs(table: string, id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      table: table,
      id: id
    }

    return this.http.post(this.URL_BASE + 'list_logs', data).toPromise();
  }

  upsert_perizia(item: IPerizia): Observable<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }


    const request = new HttpRequest('POST', this.URL_BASE + 'upsert_perizia', data, {
      reportProgress: true
    });



    return this.http.request(request);
  }

  upsert_perizia_accept_reject(id: number, nextStatus: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      nextStatus: nextStatus
    }

    return this.http.post(this.URL_BASE + 'upsert_perizia_accept_reject', data).toPromise();
  }


  upsert_allegati_perizia(item: IPerizia): Observable<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }


    const request = new HttpRequest('POST', this.URL_BASE + 'upsert_allegati_perizia', data, {
      reportProgress: true
    });



    return this.http.request(request);
  }

  delete_perizia(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'delete_perizia', data).toPromise();
  }



  get_current_user_profile(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'get_current_user_profile', data).toPromise();

  }

  //////////////////////////////////////////////////////////////////////////////////////////


  getListChat(code: string, email: string): Observable<IChat[]> {

    let data = {
      auth: this.userService.getCurrentUser(),
      code: code,
      email: email
    }

    return this.http.post<IChat[]>(this.URL_BASE + 'list-chat', data);

  }


  getListChatNotifies(): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser()
    }

    return this.http.post<IChat[]>(this.URL_BASE + 'list-chat-notifies', data).toPromise();

  }

  addChat(chat: IChat): Promise<any> {

    if (!chat.sender) chat.sender = this.userService.user.email;

    let data = {
      auth: this.userService.getCurrentUser(),
      data: chat
    }

    console.log(data)

    return this.http.post<IChat[]>(this.URL_BASE + 'add-chat', data).toPromise();

  }


  //////////////////////////////////////////////////////////////////////////////////////////

  login(email: string, password: string): Promise<any> {

    return this.http.post(this.URL_BASE + 'login', { email: email, password: password }).toPromise();

  }

  get_stats(hideCompleted = true): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      hideCompleted: hideCompleted
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'get_stats', data).toPromise();

  }

  getTipoPerizia() {
    return [
      'Stato d\'uso',
      'Buyback',
      'RC Auto',
      'Kasco collisione',
      'Fenomeni socio-politici',
      'Furto totale con ritrovamento',
      'Incendio e scoppio',
      'RC Prodotto',
      'Rivalse',
      'Globale fabbricati',
      'Danno merci',
      'Preassuntiva',
      'Kasco completa',
      'Atti vandalici',
      'Fenomeni naturali',
      'Furto parziale',
      'Cristalli',
      'RC Professionale',
      'RC Generale',
      'Acqua condotta',
      'Merci trasportate',
      'Stato d\'uso',
      'Uxpert'
    ]
  }

  getStatiPerizia(): any[] {

    if (this.stati_perizia) {
      return this.stati_perizia;
    }

    if (!this.stati_perizia) {
      return JSON.parse(localStorage.getItem('stati_perizia'));
    }


  }

  getMarche(): any[] {

    if (this.marche) {
      return this.marche;
    }

    if (!this.marche) {
      return JSON.parse(localStorage.getItem('marche'));
    }


  }

  getStato(sts) {

    let stati = this.getStatiPerizia();


    for (let i = 0; i < stati.length; i++) {
      if (sts == stati[i].sts) {
        return stati[i]
      }
    }

    return { competence: '' };
  }

  getStatiPeriziaAll(): string[] {
    let stati = this.getStatiPerizia();

    let r = [];
    for (let i = 0; i < stati.length; i++) {
      r.push(stati[i].sts);
    }

    return r;
  }

  getStatiPeriziaAll_client(): string[] {
    let stati = this.getStatiPerizia();

    let r = [];
    for (let i = 0; i < stati.length; i++) {
      r.push(stati[i].clientDescription);
    }

    r = r.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    return r;
  }

  getPeriziaStatusFilter(): any[] {
    let r = [];
    let arr = this.userService.user.role == 'CLIENTE' ? this.getStatiPeriziaAll_client() : this.getStatiPeriziaAll();
    for (let e of arr) {
      r.push({ value: e, title: e })
    }
    return r;
  }




}


export interface IFileQueue {
  filename: string;
  content: string;
  uploadTask?: any;
  md5: string;
}
